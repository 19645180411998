.form_buttons-action {
  float: right;
}

@media screen and (max-width: 520px) {
  .category-status {
    display: flex;
    flex-direction: column;

    .ant-space-item {
      width: 100%;
      overflow-x: auto;
      margin-bottom: 20px;
    }
  }

  .category-type {
    display: flex;
    width: 100%;
    label {
      flex-grow: 1;
    }
  }

  .product-category {
    flex-direction: column;
  }

  .form_buttons-action {
    width: 100%;

    button {
      width: 100%;
    }
  }
}
