/*.table-columns-fixed .ant-table-thead > tr > .ant-table-cell {*/
/*  white-space: nowrap;*/
/*}*/

.table-striped-rows tr:nth-child(2n) td {
  background-color: #fbfbfb;
}

.table-striped-rows .ant-table-tbody .ant-table-cell {
  white-space: nowrap;
}
