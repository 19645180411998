.category-space {
  flex: 1;

  & > .ant-space-item {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

.category-select {
  @media (max-width: 1024px) {
    width: 98%;
  }

  @media (min-width: 1024px) {
    width: 60%;
  }
}
